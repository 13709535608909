import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.css";

export default function notify({ title, text, status }) {
  new Notify({
    status: status,
    title: title,
    text: text,
    effect: "fade",
    speed: 300,
    customClass: null,
    customIcon: null,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 3000,
    gap: 20,
    distance: 20,
    type: "outline",
    position: "right top",
  });
}
